import auth0 from "auth0-js"
import { navigate } from "gatsby"
import jwtDecode from 'jwt-decode';

const isBrowser = typeof window !== "undefined";


const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.GATSBY_AUTH0_DOMAIN || "",
      clientID: process.env.GATSBY_AUTH0_CLIENTID || "",
      redirectUri: process.env.GATSBY_AUTH0_CALLBACK || "",
      responseType: "token id_token",
      scope: "openid profile email",
    })
  : {}

// insert after auth const
const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }
  // THEME DIFFERENCE: Auth always on
  let tokenString = localStorage.getItem("token")
  if (tokenString) {
    let token = JSON.parse(tokenString);
    const decodedAccessToken = jwtDecode(token.access_token);
    if (decodedAccessToken.exp < new Date().getTime()/1000) {
      return false;
    }
    return true;
  }

  return false;
}

export const coursesFromJWT = () => {
  if (!isBrowser) {
    return [];
  }
  let tokenString = localStorage.getItem("token")
  if (tokenString) {
    let token = JSON.parse(tokenString);
    const decodedAccessToken = jwtDecode(token.access_token);
    return decodedAccessToken.courses;
  }
  return []
}

export const preSaleCoursesFromJWT = () => {
  if (!isBrowser) {
    return [];
  }
  let tokenString = localStorage.getItem("token")
  if (tokenString) {
    let token = JSON.parse(tokenString);
    const decodedAccessToken = jwtDecode(token.access_token);
    return decodedAccessToken?.presale;
  }
  return []
}

export const isAuthorized = (courseID) => {
  if (!isAuthenticated()){
    navigate("/register/")
    return;
  }

  let allowedCourses = coursesFromJWT()
  let preSaleCourses = preSaleCoursesFromJWT()

  if (preSaleCourses.includes(parseInt(courseID))){
    return false;
  }

  if (allowedCourses.includes(parseInt(courseID))){
    return true;
  }
}

export const login = () => {
  if (!isBrowser) {
    return
  }

  auth.authorize()
}


export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }

  auth.parseHash(setSession())
}


// Logging out is just deleting the jwt.
export const logout = () => {
  if (!isBrowser) {
    return
  }
  // Add here any other data that needs to be deleted from local storage
  // on logout
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  navigate("/login/")
}



export const register = () => {
  if (!isBrowser) {
    return;
  }

  return;
};

const setSession = (cb = () => {}) => (err, authResult) => {
  if (!isBrowser) {
    return;
  }
  return;
};

export const silentAuth = (callback) => {
  if (!isAuthenticated()) return callback();
  auth.checkSession({}, setSession(callback));
};